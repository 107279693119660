/*!
 * lib/flash-message
 *
 * @author Andrew Hill
 */
var cookie = require('lib/cookie');

$(function(){
    if(cookie.get("flash-home-modal") != 'true'){
        $('#message').show();
    }

    $('#flash-message-closer').click(function(){
        $('#message').hide();
        cookie.set("flash-home-modal", "true", "", "/", "", false);
    });
});